import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './services.scss';
import Header from '../header';
import Footer from '../footer';
import about from '../images/about_us.png';
import ngright from '../images/ng-right.png';
import { Helmet } from "react-helmet";

const Services = () => {
    const [name, setName] = useState();
    const [fullName, setFullName] = useState();
    const inputEvent = (event) => {
      setName(event.target.value);
    };
    const onSubmit = () =>{
        setFullName(name);
    }
  return (
    <React.Fragment>
        <Helmet>
            <title>Top Digital Marketing Company in Noida: SEO, Web Development, PPC, and More</title>
            <meta name="description" content="Looking for top-notch digital marketing in Noida? Our SEO company offers comprehensive services including email marketing, PPC, web development, graphic design, and more. Contact the best digital marketing company near you today!" />
            <link rel="canonical" href="https://www.wenidi.com" />
            <meta name="keywords" content="Digital Marketing Company in Noida" />
            <meta property="og:title" content="Top Digital Marketing Company in Noida: SEO, Web Development, PPC, and More" />
            <meta property="og:description" content="Looking for top-notch digital marketing in Noida? Our SEO company offers comprehensive services including email marketing, PPC, web development, graphic design, and more. Contact the best digital marketing company near you today!" />
            <meta property="og:image" content="https://www.wenidi.com/" />
            <meta property="og:url" content="https://your-website.com/" ></meta>
            <meta name="twitter:title" content="Top Digital Marketing Company in Noida: SEO, Web Development, PPC, and More" />
            <meta name="twitter:description" content="Looking for top-notch digital marketing in Noida? Our SEO company offers comprehensive services including email marketing, PPC, web development, graphic design, and more. Contact the best digital marketing company near you today!." />
            <meta name="twitter:url" content="https://www.wenidi.com/" />
            <meta name="twitter:card" content="Maximize your digital impact with Wenidi's SEO mastery"></meta>
        </Helmet>
      <div>
          <Header/>
          <div className="container">
          <div className="banner-info" style={{marginBottom:0}}>
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>Services</h1>
                          <p>Discover Our Range of Digital Marketing Solutions: Masterful SEO, Effective PPC Management, Engaging Social Media Marketing, and Creative Content Creation.</p>
                      </div>
                      <div className="col-sm-4">
                        <img src={about}/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="solution">
                <div className="headingDataInfo">
                    <span className="childHead">FULL SPECTRUM TECHNOLOGY EXPERTISE</span>
                    <h1>Digital Transformation <b> Services </b></h1>
                </div>
                <div className="container">
                    <div className='technologySection'>
                        <div className='cardSectionInfo'>
                           <img alt="Email Marketing" src="images/services/email_market.jpg"/>
                           <h3>Email Marketing</h3> 
                           <p>Unlock the advantages of email marketing by maximizing your impact with targeted, creative email campaigns. Deliver your message directly to the right inboxes, ensuring your audience receives relevant and engaging content that drives results.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Search Engine Optimization" src="images/services/seo.jpg"/>
                           <h3>Search Engine Optimization</h3> 
                           <p>Boost your website's visibility and attract more traffic with our specialized SEO strategies, tailored to your unique business needs. Discover free tricks and tips that will help you enhance your online presence effortlessly.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Ecommerce" src="images/services/eCommerce_web.jpg"/>
                           <h3>eCommerce Web Design</h3> 
                           <p>Elevate your brand with a tailor-made eCommerce website, optimized for seamless shopping experiences and maximum conversion rates. Our eCommerce web development services focus on creating user-friendly, visually stunning online stores that not only attract customers but also keep them coming back.
                           </p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Graphics Design" src="images/services/graphics.jpg"/>
                           <h3>Graphic Designing</h3> 
                           <p>Looking to elevate your visual identity? Our Graphic Design Institute in noida offers creative solutions tailored to set your brand apart in a competitive market. Join us to transform your ideas into stunning visuals and stand out with unique, professional designs.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Social Media Marketing" src="images/services/social_media.jpg"/>
                           <h3>Social Media Marketing</h3> 
                           <p>Welcome to Social Brand Hub! Elevate your online presence with our targeted social media strategies, designed to drive meaningful engagement and turn followers into loyal customers. Let us help you build a thriving community around your brand.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Pay per click" src="images/services/ppc.jpg"/>
                           <h3>Pay per Click</h3> 
                           <p>Drive instant traffic and increase conversions with our customized pay-per-click (PPC) advertising in India. Our PPC campaigns are designed for precision targeting and optimal ad performance, ensuring you reach the right audience and achieve your business goals efficiently.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Video Editing" src="images/services/video.jpg"/>
                           <h3>Video Production</h3> 
                           <p>Transform your marketing strategy with our bespoke short video maker. Our tailored video production showcases your brand's unique narrative and creates an emotional connection with viewers, making your message memorable and impactful.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Content Writing" src="images/services/content.jpg"/>
                           <h3>Content Writing</h3> 
                           <p>Elevate your brand's voice with our expert content writing services. We specialize in various types of content writing, crafting compelling and engaging narratives that resonate with your audience. Whether you need blog posts, social media content, website copy, or email newsletters, our team ensures your message is heard loud and clear. </p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Reputation Managment" src="images/services/reputation.jpg"/>
                           <h3>Reputation Management</h3> 
                           <p>Protect and enhance your brand's reputation with Percepto's comprehensive reputation management services. Our tailored approach builds trust and maintains a positive online presence, ensuring your brand stands out for all the right reasons. Trust Percepto to manage your reputation with expertise and care.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Conversion Optmization" src="images/services/conversion.jpg"/>
                           <h3>Conversion Optimization</h3> 
                           <p>Imagine having a team of experts dedicated to maximizing every opportunity on your website. That’s exactly what conversion optimization services offer. They're like your personal strategists, fine-tuning your site so that every click counts—turning visitors into loyal customers. With tailored strategies, making sure each interaction leads to more engagement and higher conversion rates. </p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Shopify Development" src="images/services/shopify.jpg"/>
                           <h3>Shopify Web Development</h3> 
                           <p>Looking to build and maintain an online store effortlessly? Consider teaming up with a Shopify development company. Shopify is the go-to solution for ecommerce websites, known for its user-friendly setup and management. Get started today and watch your business grow online!</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Website Development" src="images/services/mobile_app.jpg"/>
                           <h3>Website & Mobile Apps Development</h3> 
                           <p>Experience the power of our Website & Mobile Apps Development services. We specialize in crafting visually stunning and user-friendly digital solutions that elevate your brand's online presence. Whether you need a new website from scratch or want to enhance your mobile app, we're here to bring your vision to life.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ourServices'>
                <div className='container'>
                    <h5>OUR SERVICES</h5>
                    <p>We do website design & development,
                    Digital Marketing, SEO, Web App, Mobile
                    App. </p>
                    <br/><br/>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center px-4 py-5">
                            <div className="feature-box-icon"><img className="w-25" src="images/check-mark.svg" alt=""/></div>
                            <h3 className="feature-box-title">ESTABLISH YOUR DIGITAL FOOTPRINT.</h3>
                            <p className="feature-box-desc">Create a lasting first impression with our bespoke web design services, crafting visually stunning and userfriendly websites that elevate your brand's online presence</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center all-text-white bg-grad px-4 py-5 border-radius-3">
                            <div className="feature-box-icon"><img className="w-25" src="images/editor.svg" alt=""/></div>
                            <h3 className="feature-box-title">WHY WENIDI?</h3>
                            <p className="feature-box-desc">Partner with Wenidi for expert SEO services, resultdriven PPC campaigns, and dynamic social media marketing tailored to your business needs</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center px-4 py-5">
                            <div className="feature-box-icon"><img className="w-25" src="images/envelope.svg" alt=""/></div>
                            <h3 className="feature-box-title">WHAT WE DO?</h3>
                            <p className="feature-box-desc">We turn your digital goals into reality. Specializing in SEO, social media, and content marketing, we're here to help your brand shine in the digital universe.</p>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
            <div class="trusted-data">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="content">
                                    {/* <p>"We are the most trusted business partner
                                    for Consulting solution by our clients"</p> */}
                                    <p>"Your trusted partner in IT consulting, crafting technology solutions that fit your needs."</p>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="br-right">
                                        <img src={ngright}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>       
          <Footer/>
      </div>
      </React.Fragment>
    );
}

export default Services;