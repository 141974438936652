import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';
import './header.scss';
import { Helmet } from "react-helmet";
const Header = () => {
    const schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Wenidi Technologies Pvt Ltd",
        "alternateName": "Wenidi",
        "url": "https://www.wenidi.com",
        "logo": "https://www.wenidi.com/images/wenidiLogo.png",
        "sameAs": [
            "https://www.facebook.com/people/Wenidi/100069623461291/",
            "https://www.instagram.com/wenidi_inc/",
            "https://www.linkedin.com/company/wenidi-technologies/",
            ""
        ]
    }
  return (
    <React.Fragment>
        <Helmet>
            <title>Top Web Development Company Near Me: Internships, Mobile App Development, and More</title>
            <meta name="description" content="Find the best web development company near you offering internships, website development, and mobile app development services. Boost your digital presence with top-notch solutions today!" />
            <link rel="canonical" href="https://www.wenidi.com" />
            <meta name="keywords" content="web development company near me" />
            <meta property="og:title" content="Top Web Development Company Near Me: Internships, Mobile App Development, and More" />
            <meta property="og:description" content="Find the best web development company near you offering internships, website development, and mobile app development services. Boost your digital presence with top-notch solutions today!" />
            <meta property="og:image" content="https://www.wenidi.com/" />
            <meta property="og:url" content="https://your-website.com/" ></meta>
            <meta name="twitter:title" content="Top Web Development Company Near Me: Internships, Mobile App Development, and More" />
            <meta name="twitter:description" content="Find the best web development company near you offering internships, website development, and mobile app development services. Boost your digital presence with top-notch solutions today!" />
            <meta name="twitter:url" content="https://www.wenidi.com/" />
            <meta name="twitter:card" content="Maximize your digital impact with Wenidi's SEO mastery"></meta>
            <script type="application/ld+json">
                {JSON.stringify(schema)}
            </script>
            
        </Helmet>
    <StickyHeader 
    header={
        
    <header className="header-top">
        <nav id="navbar_top" className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
        <NavLink  className="navbar-brand" to="/"><img src="/images/wenidiLogo.png" className="App-logo" alt="logo" /></NavLink>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto action-buttons">
                <li class="nav-item">
                <NavLink exact activeClassName="active" className="nav-link" to="/">Home <span class="sr-only">(current)</span></NavLink>
                </li>
                <li class="nav-item dropdown">
                    <NavLink activeClassName="active" className="nav-link" to="/services">Services <span style={{position:'relative', top:0}}><i className="fa fa-angle-down" aria-hidden="true"></i></span></NavLink>
                    <ul class="dropdown-menu">
                        <li><NavLink className="dropdown-item" to="/services">Our Services</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/seo-digital-marketing/">SEO & Digital Marketing</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/website-&-applications">Website & Applications</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/our-skillsets">Our Skillsets</NavLink></li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <div className="nav-link">Product <span style={{position:'relative', top:0}}><i className="fa fa-angle-down" aria-hidden="true"></i></span></div>
                    <ul class="dropdown-menu">
                        <li><a className='dropdown-item' href="https://tocgenerator.wenidi.com/" target="_blank">Table of Contents Generator</a></li>
                    </ul>
                </li>
                <li class="nav-item">
                    <NavLink activeClassName="active" className="nav-link" to="/about">About Us</NavLink>
                </li>
                <li class="nav-item dropdown">
                    <a className='nav-link' href='javascript:void(0)'> Career <span style={{position:'relative', top:0}}><i className="fa fa-angle-down" aria-hidden="true"></i></span></a>
                    <ul class="dropdown-menu">
                        <li><NavLink className="dropdown-item" to="/career">Current Positions</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/internship-program">Internship Program</NavLink></li>
                    </ul>
                    </li>
                <li class="nav-item">
                    <NavLink activeClassName="active" className="nav-link" to="/wenidi-blogs">Blogs</NavLink>
                </li>
                    <li class="nav-item">
                    <NavLink activeClassName="active" className="nav-link" to="/contact">Contact Us</NavLink>
                </li>
                </ul>
            </div>
        </div>
        </nav>
    </header>
    } >
     </StickyHeader>
     </React.Fragment>
  );
}

export default Header;
