// jobData.js
const jobData = [
  {
    technology: "E-Commerce Specialist: Amazon, Shopify & Product Development",
    location: "India",
    type: "Full-time",
    button:"Apply now"
  },
];

export default jobData;
