import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './services.scss';
import Header from '../header';
import Footer from '../footer';
import ngright from '../images/ng-right.png';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";

const SEOData = () => {
    const [name, setName] = useState();
    const [fullName, setFullName] = useState();
    const inputEvent = (event) => {
      setName(event.target.value);
    };
    const onSubmit = () =>{
        setFullName(name);
    }
  return (
    <React.Fragment>
        <Helmet>
            <title>Expert Digital Marketing Projects: Elevate Your Business with Proven Strategies</title>
            <meta name="description" content="Discover top-notch digital marketing projects to elevate your business. Our expert team specializes in SEO, PPC, email marketing, web development, and more." />
            <link rel="canonical" href="https://www.wenidi.com" />
            <meta name="keywords" content="Digital Marketing Projects" />
            <meta property="og:title" content="Expert Digital Marketing Projects: Elevate Your Business with Proven Strategies" />
            <meta property="og:description" content="Discover top-notch digital marketing projects to elevate your business. Our expert team specializes in SEO, PPC, email marketing, web development, and more." />
            <meta property="og:image" content="https://www.wenidi.com/" />
            <meta property="og:url" content="https://your-website.com/" ></meta>
            <meta name="twitter:title" content="Expert Digital Marketing Projects: Elevate Your Business with Proven Strategies" />
            <meta name="twitter:description" content="Discover top-notch digital marketing projects to elevate your business. Our expert team specializes in SEO, PPC, email marketing, web development, and more." />
            <meta name="twitter:url" content="https://www.wenidi.com/" />
            <meta name="twitter:card" content="Maximize your digital impact with Wenidi's SEO mastery"></meta>

        </Helmet>
      <div>
          <Header/>
          <div className="container">
          <div className="banner-info" style={{marginBottom:0}}>
             
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>SEO & Digital Marketing</h1>
                          <p>We have experience of 6000+ man hours of SEO, Digital Marketing & Website Designs.</p>
                      </div>
                      <div className="col-sm-4">
                         <img src="/images/seo.svg"/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
            <div className="solution">
                <div className="headingDataInfo">
                    <span className="childHead">SEO & Digital Marketing</span>
                    <h1>Boost Your Online Presence with Our Comprehensive Solutions</h1>
                </div>
              <div className='container'>
              <div className="absolute">
                            <div className="row">
                                <div className="col-sm-6">
                                <div className="absolute-content seo">
                                    <h1>At Wenidi, we offer a wide range of SEO and digital marketing services tailored to meet your unique business needs.</h1>
                                    <p>Ready to take your online presence to the next level? Contact Wenidi today to discuss your digital marketing goals.</p>
                                    <ul>
                                        <li>Unlock Top Search Engine Rankings</li>
                                        <li>Keyword Research</li>
                                        <li>On-Page Optimization</li>
                                        <li>Link Building</li>
                                        <li>Unlock Top Search Engine Rankings</li>
                                        <li>Content Marketing</li>
                                        <li>Social Media Management</li>
                                        <li>Paid Advertising (PPC)</li>
                                        <li>Analytics and Reporting</li>
                                        <li>Local SEO</li>
                                        <li>E-commerce SEO</li>
                                        <li>Reputation Management</li>
                                    </ul>
                                    <NavLink to="/contact"><a className='btn-primary'>Get Started</a></NavLink>
                                    </div>
                                </div>
                        <div className="col-sm-6">
                        <img src="/images/digital-seo_3.png"/>
                       </div>
                    </div>
                </div>
              </div>
            </div>
            <div className='transformSeo'>
                <div className='container'>
                    <div className="absolute">
                            <div className="row">
                              <div className="col-sm-6">
                                <h3>Transforming
                                    Your Digital
                                    Innovation</h3>
                              </div>
                              <div className="col-sm-6">
                                <img src="/images/tra.png" style={{float:"right"}}/>
                              </div>
                            </div>
                    </div>
                </div>
            </div>
            {/* <div className='oursBasicServiceStart'>

            <div className="container">
                <div className="row">
                <div className="col-12 col-lg-8 mx-auto mb-5">
                    <div className="title text-center">
                    <h2>Our basic services starts only at,</h2>
                    <p>We have experience of 6000+ man hours of SEO, Digital Marketing & Website Designs.</p>
                    </div>
                </div>
                </div>
                <div className="row spacePadding"> 
                <div className="col-md-6 ">
                    <div className="pricing-box h-100 shadow no-border box">
                    <div className="ribbon"><span>POPULAR</span></div>
                    <h5>Digital Marketing Services <b>(India)</b></h5>
                    <br/>
                    <div className="plan-price"> <span className="price text-grad"> <sup className="text-grad">Rs.</sup>11000* </span> / month. taxes extra </div>
                    <p>Search Engine Optimization*</p>
                    <p>Social Media Marketing *</p>
                    <p>Pay-Per-Click Advertising*</p>
                    <p>Content Marketing**</p>
                    <NavLink to="/contact"><a className="btn btn-gradNew mt-4" href="#!">Get Started</a></NavLink> </div>
                </div>
                <div className="col-md-6 ">
                    <div className="pricing-box h-100 shadow no-border box">
                    <div className="ribbon"><span>POPULAR</span></div>
                    <h5>Digital Marketing Services <b>(Outside India)</b></h5>
                    <br/>
                    <div className="plan-price"> <span className="price text-grad"> <sup className="text-grad">$</sup>300* </span> / month. taxes extra </div>
                    <p>Search Engine Optimization*</p>
                    <p>Social Media Marketing *</p>
                    <p>Pay-Per-Click Advertising*</p>
                    <p>Content Marketing**</p>
                    <NavLink to="/contact"><a className="btn btn-gradNew mt-4" href="#!">Get Started</a></NavLink> </div>
                </div>
                </div>
            </div>
            </div> */}
            <div className="trusted-data">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="content">
                                    {/* <p>"We are the most trusted business partner
                                    for Consulting solution by our clients"</p> */}
                                    <p>"Your trusted partner in IT consulting, crafting technology solutions that fit your needs."</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="br-right">
                                        <img src={ngright}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>       
          <Footer/>
      </div>
      </React.Fragment>
    );
}

export default SEOData;