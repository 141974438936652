import React from 'react';
import { NavLink } from 'react-router-dom';
import './slider.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Slider() {
  return (
    <div className='container'>
    <OwlCarousel className='owl-theme main' items="1" loop margin={10}  dots autoplay>
    <div class='item'>
      <div class="container">
          <div class="carousel-caption text-left">
              <div class="row">
                <div class="col-sm-7">
                      <h2>Welcome to Wenidi, Where Innovation Meets Practically.</h2>
                      <NavLink to="/contact"><button className='btn-primary'>Connect</button></NavLink>
                </div>
                <div class="col-sm-5">
                   <div className='innovation'>
                      <img src="./images/innovation_3.png" alt="Innovation"/>
                   </div>
                </div>
               </div>
          </div>
        </div>
    </div>
    <div class='item'>
      <div class="container">
          <div class="carousel-caption text-left">
              <div class="row">
                <div class="col-sm-7">
                      <h2>Let's take your journey digitally with us.</h2>
                      <NavLink to="/contact"><button className='btn-primary'>Connect</button></NavLink>
                </div>
                <div class="col-sm-5">
                   <div className='innovation'>
                      <img src="./images/digital.png" alt="Innovation"/>
                   </div>
                </div>
               </div>
          </div>
        </div>
    </div>
    <div class='item'>
      <div class="container">
          <div class="carousel-caption text-left">
              <div class="row">
                <div class="col-sm-7">
                      <h2>Transforming your ideas into realities.</h2>
                      <NavLink to="/contact"><button className='btn-primary'>Connect</button></NavLink>
                </div>
                <div class="col-sm-5">
                   <div className='innovation'>
                      <img src="./images/ideas.png" alt="Innovation"/>
                   </div>
                </div>
               </div>
          </div>
        </div>
    </div>
    <div class='item'>
      <div class="container">
          <div class="carousel-caption text-left">
              <div class="row">
                <div class="col-sm-7">
                      <h2>Let's explore the dynamic platform of Wenidi.</h2>
                      <NavLink to="/contact"><button className='btn-primary'>Connect</button></NavLink>
                </div>
                <div class="col-sm-5">
                   <div className='innovation' style={{marginTop: 40}}>
                      <img src="./images/dynamic.png" alt="Innovation"/>
                   </div>
                </div>
               </div>
          </div>
        </div>
    </div>
   </OwlCarousel>
   </div>
    ); 
 }

export default Slider;