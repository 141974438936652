import React from 'react';
import './testimonial.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Testimonials() {
  return (
    <div className='owlDataNew'>
      <OwlCarousel className='owl-theme' items="1" loop margin={10}  dots nav autoplay>
      <div class='itemNew'>
            <div class="carousel-caption text-left">
              <div class="row">
                <div class="testMonData">
                      <div className='userIcondata'>
                        <div className='circle'>
                          <img src="./images/profile.png"/>
                        </div>
                        <div className='rightCnt'>
                          <h6>Amit</h6>
                          <p>CEO, Amit Packers</p>
                        </div>
                      </div>
                      <div className='userLorem'>
                        <p>“Choosing Wenidi for digital marketing and SEO was a game-changer. Their strategic approach and expertise not only boosted our online presence but also significantly contributed to our business growth. Highly recommended!”</p>
                      </div>
                </div>
              </div>
            </div>
       </div>
       {/* <div class='itemNew'>
            <div class="carousel-caption text-left">
              <div class="row">
                <div class="testMonData">
                      <div className='userIcondata'>
                        <div className='circle'>
                          <img src="./images/profile.png"/>
                        </div>
                        <div className='rightCnt'>
                          <h6>Ashish</h6>
                          <p>Owner, Nexus Trading Company</p>
                        </div>
                      </div>
                      <div className='userLorem'>
                        <p>“Thanks to Wenidi, our online visibility soared with their stellar digital marketing and SEO solutions. Their expert team delivered tangible results, proving them to be a key asset in our business success.”</p>
                      </div>
                </div>
              </div>
            </div>
       </div> */}
       <div class='itemNew'>
            <div class="carousel-caption text-left">
              <div class="row">
                <div class="testMonData">
                      <div className='userIcondata'>
                        <div className='circle'>
                          <img src="./images/profile.png"/>
                        </div>
                        <div className='rightCnt'>
                          <h6>Amit Goel</h6>
                          <p>CEO, Headlocks Luxury Salon</p>
                        </div>
                      </div>
                      <div className='userLorem'>
                        <p>“Wenidi transformed our online presence with exceptional digital marketing and SEO services. Their expertise, dedication, and results-driven approach made them an invaluable partner in our business growth.”</p>
                      </div>
                </div>
              </div>
            </div>
       </div>
       <div class='itemNew'>
            <div class="carousel-caption text-left">
              <div class="row">
                <div class="testMonData">
                      <div className='userIcondata'>
                        <div className='circle'>
                           <img src="./images/profile.png"/>
                        </div>
                        <div className='rightCnt'>
                          <h6>Sunil Singh Rathore</h6>
                          <p>COO, Sai Retail</p>
                        </div>
                      </div>
                      <div className='userLorem'>
                        <p>“I'm thrilled to endorse Wenidi. Their expertise, attention to detail, and exceptional project management skills resulted in a stunning, user-friendly website that met my vision. Their post-launch support is also fantastic. I wholeheartedly recommend Wenidi for anyone seeking top-notch website development.”</p>
                      </div>
                </div>
              </div>
            </div>
       </div>
       <div class='itemNew'>
            <div class="carousel-caption text-left">
              <div class="row">
                <div class="testMonData">
                      <div className='userIcondata'>
                        <div className='circle'>
                           <img src="./images/profile.png"/>
                        </div>
                        <div className='rightCnt'>
                          <h6>Arvind Mourya</h6>
                          <p>CFO, Yogini Activewear</p>
                        </div>
                      </div>
                      <div className='userLorem'>
                        <p>“Wenidi revolutionized my digital presence! Their SEO strategies propelled my business to new heights. With precision and innovation, they crafted an online strategy that truly stands out. Highly recommend Wenidi for unparalleled digital marketing and SEO expertise!”</p>
                      </div>
                </div>
              </div>
            </div>
       </div>
    </OwlCarousel>
   </div>
    ); 
 }

export default Testimonials;