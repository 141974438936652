import React from 'react';
import './testimonialEmployee.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function TestimonialsEmployee() {
  return (
    <div className='owlDataNew'>
      <OwlCarousel className='owl-theme' items="1" loop margin={10}  dots nav autoplay>
      <div class='itemNew'>
            <div class="carousel-caption text-left">
              <div class="row">
                <div class="testMonData">
                      <div className='userIcondata'>
                        <div className='circle'>
                          <img src="images/satyam.jpeg" alt="Satyam"/>
                        </div>
                        <div className='rightCnt'>
                          <h6>Satyam Dubey</h6>
                          <p>Director of Engineering</p>
                        </div>
                      </div>
                      <div className='userLorems'>
                        <p>“As the Director of Engineering at Wenidi, I'm continuously inspired by the innovative spirit and technical prowess of our team. We don't just solve problems, we pave the way for new industry standards. The collaborative environment encourages continuous learning, and leadership support in pushing boundaries truly sets us apart. At Wenidi, we're not just building software, we're crafting the future.”</p>
                      </div>
                </div>
              </div>
            </div>
       </div>
       <div class='itemNew'>
            <div class="carousel-caption text-left">
              <div class="row">
                <div class="testMonData">
                      <div className='userIcondata'>
                        <div className='circle'>
                          <img src="images/aditya.jpeg" alt="Aditya"/>
                        </div>
                        <div className='rightCnt'>
                          <h6>Aditya Upadhyay</h6>
                          <p>Senior Software Engineer</p>
                        </div>
                      </div>
                      <div className='userLorems'>
                        {/* <p>“What really stood out to me about Wenidi was the level of support they offered throughout the integration process. The team was extremely knowledgeable and responsive, and they went above and beyond to ensure that we were able to successfully integrate our applications.”</p> */}
                        <p>“Working at Wenidi as a Senior Software Engineer has been a rewarding journey. Every day presents a new challenge, a new opportunity to grow and innovate. The camaraderie among colleagues and the transparent communication with the management has fostered a nurturing environment. Here, I feel valued, empowered, and an integral part of a team that's shaping the technological landscape.”</p>
                      </div>
                </div>
              </div>
            </div>
       </div>
       { /*<div class='itemNew'>
            <div class="carousel-caption text-left">
              <div class="row">
                <div class="testMonData">
                      <div className='userIcondata'>
                        <div className='circle'>
                          <img src="images/shreyansh.jpeg" alt="Shreyansh"/>
                        </div>
                        <div className='rightCnt'>
                          <h6>Shreyansh Kumar</h6>
                          <p>Graphics Designer</p>
                        </div>
                      </div>
                      <div className='userLorems'>
                        
                        <p>“Creativity thrives at Wenidi, and I've found my perfect canvas as a Graphics Designer here. The blend of artistic freedom with technical precision offers endless possibilities to explore and create. The inclusive and vibrant culture fuels inspiration, and the recognition of my work's impact on our clients success has made my experience here more fulfilling. Wenidi has not just been a workplace for me, it's been a platform to grow, create, and innovate.”</p>                      
                      </div>
                </div>
              </div>
            </div>
      </div> */ }
    </OwlCarousel>
   </div>
    ); 
 }

export default TestimonialsEmployee;