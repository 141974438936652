import React from 'react';
import { NavLink } from 'react-router-dom';
import absotule from '../images/absotule.png';
import feature from '../images/feature.png';
import security from '../images/security.png';
import ngright from '../images/ng-right.png';
import sme from '../images/SME.png';
import enter from '../images/enter.png';
import solution from '../images/solution.png';
import 'bootstrap/dist/css/bootstrap.css';
import './main.scss';
import Testimonials from '../testimonial';
import TestimonialsClient from '../testimonialClient';

function Main() {
    return (
        <div>
            <div className="container marketing">
                <div className='outerTrustedPartner'>
                    <div className='contentInfoDataStay'>
                        <div className='contentsty'>
                            <h1>Digital Marketing Evolution: Keeping Your Strategies Updated for Success</h1>
                            <p>Stay ahead in the digital landscape with our continuous marketing updates. Amplify your online presence, adapt to evolving trends, & ensure your strategies remain cutting-edge for sustained success.</p>
                        </div>
                        <NavLink to="/contact"><button className='bookingInfo'>Book a Meeting</button></NavLink>
                    </div>
                </div>
                <div className='outerTrustedPartner box-shadow'>
                    <h2>Our Trusted Customers</h2>
                    <p>SUPPORT FROM ELEVATED BUSINESSES</p>
                    <TestimonialsClient/>
                </div>
            </div>
            <div className="solution">
                <div className="headingDataInfo">
                    <span className="childHead">FULL SPECTRUM TECHNOLOGY EXPERTISE</span>
                    <h2>Digital Transformation <b> Services </b></h2>
                </div>
                <div className="container">
                    <div className='technologySection'>
                        <div className='cardSectionInfo'>
                           <img alt="Email Marketing" src="images/services/email_market.jpg"/>
                           <h3>Email Marketing</h3> 
                           <p>Unlock the advantages of email marketing by maximizing your impact with targeted, creative email campaigns. Deliver your message directly to the right inboxes, ensuring your audience receives relevant and engaging content that drives results.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Search Engine Optimization" src="images/services/seo.jpg"/>
                           <h3>Search Engine Optimization</h3> 
                           <p>Boost your website's visibility and attract more traffic with our specialized SEO strategies, tailored to your unique business needs. Discover free tricks and tips that will help you enhance your online presence effortlessly.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Ecommerce" src="images/services/eCommerce_web.jpg"/>
                           <h3>eCommerce Web Design</h3> 
                           <p>Elevate your brand with a tailor-made eCommerce website, optimized for seamless shopping experiences and maximum conversion rates. Our eCommerce web development services focus on creating user-friendly, visually stunning online stores that not only attract customers but also keep them coming back.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Graphics Design" src="images/services/graphics.jpg"/>
                           <h3>Graphic Designing</h3> 
                           <p>Looking to elevate your visual identity? Our Graphic Design Institute in noida offers creative solutions tailored to set your brand apart in a competitive market. Join us to transform your ideas into stunning visuals and stand out with unique, professional designs.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Social Media Marketing" src="images/services/social_media.jpg"/>
                           <h3>Social Media Marketing</h3> 
                           <p>Welcome to Social Brand Hub! Elevate your online presence with our targeted social media strategies, designed to drive meaningful engagement and turn followers into loyal customers. Let us help you build a thriving community around your brand.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="PPC" src="images/services/ppc.jpg"/>
                           <h3>Pay per Click</h3> 
                           <p>Drive instant traffic and increase conversions with our customized pay-per-click (PPC) advertising in India. Our PPC campaigns are designed for precision targeting and optimal ad performance, ensuring you reach the right audience and achieve your business goals efficiently.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Video Editing" src="images/services/video.jpg"/>
                           <h3>Video Production</h3> 
                           <p>Transform your marketing strategy with our bespoke short video maker. Our tailored video production showcases your brand's unique narrative and creates an emotional connection with viewers, making your message memorable and impactful.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Content Writer" src="images/services/content.jpg"/>
                           <h3>Content Writing</h3> 
                           <p>Elevate your brand's voice with our expert content writing services. We specialize in various types of content writing, crafting compelling and engaging narratives that resonate with your audience. Whether you need blog posts, social media content, website copy, or email newsletters, our team ensures your message is heard loud and clear.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Reputation Managment" src="images/services/reputation.jpg"/>
                           <h3>Reputation Management</h3> 
                           <p>Protect and enhance your brand's reputation with Percepto's comprehensive reputation management services. Our tailored approach builds trust and maintains a positive online presence, ensuring your brand stands out for all the right reasons. Trust Percepto to manage your reputation with expertise and care.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Conversion Optimization" src="images/services/conversion.jpg"/>
                           <h3>Conversion Optimization</h3> 
                           <p>Imagine having a team of experts dedicated to maximizing every opportunity on your website. That’s exactly what conversion optimization services offer. They're like your personal strategists, fine-tuning your site so that every click counts—turning visitors into loyal customers. With tailored strategies, making sure each interaction leads to more engagement and higher conversion rates.</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Shopify Development" src="images/services/shopify.jpg"/>
                           <h3>Shopify Web Development</h3> 
                           <p>Looking to build and maintain an online store effortlessly? Consider teaming up with a Shopify development company. Shopify is the go-to solution for ecommerce websites, known for its user-friendly setup and management. Get started today and watch your business grow online!</p>
                        </div>
                        <div className='cardSectionInfo'>
                           <img alt="Website development" src="images/services/mobile_app.jpg"/>
                           <h3>Website & Mobile Apps Development</h3> 
                           <p>Experience the power of our Website & Mobile Apps Development services. We specialize in crafting visually stunning and user-friendly digital solutions that elevate your brand's online presence. Whether you need a new website from scratch or want to enhance your mobile app, we're here to bring your vision to life.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='counterInfo'>
                <div className='container'>
                    <div className='conterInfoOuterData'>
                        <div className='contendata'>
                            <h4><i>77</i></h4>
                            <p>Exceptional NPS Score</p>
                        </div>
                        <div className='contendata'>
                            <h4><i>30+</i></h4>
                            <p>World Class Enterprises Served</p>
                        </div>
                        <div className='contendata'>
                            <h4><i>100+</i></h4>
                            <p>Frameworks & Accelerators</p>
                        </div>
                        <div className='contendata'>
                            <h4><i>2000+</i></h4>
                            <p>Consultants Globally</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='ourServices'>
                <div className='container'>
                    <h5>OUR SERVICES</h5>
                    <p>We do website design & development,
                    Digital Marketing, SEO, Web App, Mobile
                    App. </p>
                    <br/><br/>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center px-4 py-5">
                            <div className="feature-box-icon"><img className="w-25" src="images/check-mark.svg" alt=""/></div>
                            <h3 className="feature-box-title">ESTABLISH YOUR DIGITAL FOOTPRINT.</h3>
                            <p className="feature-box-desc">Create a lasting first impression with our bespoke web design services, crafting visually stunning and userfriendly websites that elevate your brand's online presence</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center all-text-white bg-grad px-4 py-5 border-radius-3">
                            <div className="feature-box-icon"><img className="w-25" src="images/editor.svg" alt=""/></div>
                            <h3 className="feature-box-title">WHY WENIDI?</h3>
                            <p className="feature-box-desc">Partner with Wenidi for expert SEO services, resultdriven PPC campaigns, and dynamic social media marketing tailored to your business needs</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center px-4 py-5">
                            <div className="feature-box-icon"><img className="w-25" src="images/envelope.svg" alt=""/></div>
                            <h3 className="feature-box-title">WHAT WE DO?</h3>
                            <p className="feature-box-desc">We turn your digital goals into reality. Specializing in SEO, social media, and content marketing, we're here to help your brand shine in the digital universe.</p>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
            {/* <div className="portfolio">
                <div className="headingDataInfo">
                    <span className="childHead">PORTFOLIO</span>
                    <h1>Check our  <b> Portfolio </b></h1>
                </div>
                <div className='portFolioOuter'>
                    <div className='sectionPort'>
                        <img src="./images/portfolio.png"/>
                    </div>
                    <div className='sectionPort'>
                        <img src="./images/profile2.png"/>
                    </div>
                    <div className='sectionPort'>
                        <img src="./images/portfolio-3.png"/>
                    </div>
                    <div className='sectionPort'>
                        <img src="./images/portfolio-4.png"/>
                    </div>
                    <div className='sectionPort'>
                        <img src="./images/portfolio-5.png"/>
                    </div>
                    <div className='sectionPort'>
                        <img src="./images/portfolio-6.png"/>
                    </div>
                    <div className='sectionPort'>
                        <img src="./images/profile7.png"/>
                    </div>
                    <div className='sectionPort'>
                        <img src="./images/profile8.png"/>
                    </div>
                </div>
            </div> */} 
            <div className="container marketing">
            </div>
                    <div className="company-url clientdata">
                        <div className="headingDataInfo"><h2>Our  <b>Distinguished Clients </b></h2></div>
                        <div className="container">
                        <div className="clientOuter">
                        <span className="imglogo">
                            <img style={{width: 55}} src="./Logo/headlock.jpeg" alt="client"/>
                        </span>
                        <span className="imglogo">
                            <img style={{width: 200}} src="./Logo/amit.png" alt="client"/>
                        </span>
                        <span className="imglogo">
                            <img style={{width: 130}} src="./Logo/sai.png" alt="client"/>
                        </span>
                        <span className="imglogo">
                            <img style={{width: 111}}  src="./Logo/nexus.png" alt="client"/>
                        </span>
                        <span className="imglogo">
                            <img src="https://globalpda.com/wp-content/uploads/2020/05/logo.png" alt="client"/>
                        </span>
                        <span className="imglogo">
                            <img src="./Logo/mine2.png" alt="client"/>
                        </span>
                        {/* <span className="imglogo rocket">
                            <img src="https://rocketfuel.inc/wp-content/uploads/2022/10/RocketFuelLogoR.svg" alt="client"/>
                        </span> */}
                        <span className="imglogo">
                            <img src="./Logo/senger.png" alt="client"/>
                        </span>
                        <span className="imglogo">
                            <img src="./Logo/baba_saheb.png" alt="client"/>
                        </span>
                        <span className="imglogo">
                            <img src="./Logo/yoginis.png" alt="client"/>
                        </span>
                        <span className="imglogo">
                            <img src="./Logo/gcs.png" alt="client"/>
                        </span>
                        <span className="imglogo">
                            <img src="./Logo/globalkidsolympiads_logo.png" alt="client"/>
                        </span>
                        <span className="imglogo">
                            <img src="./Logo/kaushik.png" alt="client"/>
                        </span>
                        </div>
                        </div>
                    </div>
                    <div className="testimonialsInfo">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-7">
                                    <div className='contentTst'>
                                        <h5>Our Customers</h5>
                                        <p>Read our success stories to see how we've helped businesses achieve top search engine rankings and significant ROI through expert digital marketing strategies.</p>
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                      <div className='sliderTest'>
                                          <Testimonials/>
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footerSubs'>
                        <div className='container'>
                            <div className='flexInfo'>
                                <h3>Want To Speak With Our Solution Experts?</h3>
                                <NavLink to="/contact"><button className='bookingInfo'>Book a Meeting</button></NavLink>
                            </div>
                        </div>
                    </div>
    </div>
      ); 
   }
  
  export default Main;