import React, { useState, useEffect } from "react";
import "./cookies.scss";
import { NavLink } from 'react-router-dom';

const CookiesModal = () => {
  const [showModal, setShowModal] = useState(false);

  // Check if the user has previously accepted cookies
  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem("cookiesAccepted");
    if (!hasAcceptedCookies) {
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowModal(false);
  };

  return (
    <div>
    {showModal && <div className='tearmsPrvcy'>
		      <span>This website uses cookies to improve your experience. We'll assume you're ok with this, but you can opt-out if you wish. <NavLink to="/terms"><a role="button" className="cli_settings_button">Cookie settings</a></NavLink><a className="medium cli-plugin-button cli-plugin-main-button cookie_action_close_header cli_action_button" onClick={handleCloseModal}>ACCEPT</a></span>
		   </div>}
    </div>
  );
};

export default CookiesModal;
