import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './services.scss';
import Header from '../header';
import Footer from '../footer';
import ngright from '../images/ng-right.png';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";

const WebApplication = () => {
    const [name, setName] = useState();
    const [fullName, setFullName] = useState();
    const inputEvent = (event) => {
      setName(event.target.value);
    };
    const onSubmit = () =>{
        setFullName(name);
    }
  return (
    <React.Fragment>
        <Helmet>
            <title>Make Website & Mobile Apps - wenidi.com</title>
            <meta name="description" content="Make a digital presence with Web & Mobile Applications, ensuring seamless success. Unleash the power of innovative solutions for a triumphant online experience." />
            <link rel="canonical" href="https://www.wenidi.com" />
            <meta name="keywords" content="website development, mobile app development, web developer, web development services,
                web development company near me, website developer services, mobile app development company, mobile app services" />
            <meta property="og:title" content="Make Website & Mobile Apps - wenidi.com" />
            <meta property="og:description" content="Make a digital presence with Web & Mobile Applications, ensuring seamless success. Unleash the power of innovative solutions for a triumphant online experience." />
            <meta property="og:image" content="https://www.wenidi.com/" />
            <meta property="og:url" content="https://your-website.com/" ></meta>
            <meta name="twitter:title" content="Make Website & Mobile Apps - wenidi.com" />
            <meta name="twitter:description" content="Make a digital presence with Web & Mobile Applications, ensuring seamless success. Unleash the power of innovative solutions for a triumphant online experience." />
            <meta name="twitter:url" content="https://www.wenidi.com/" />
            <meta name="twitter:card" content="Maximize your digital impact with Wenidi's SEO mastery"></meta>
            
        </Helmet>
      <div>
          <Header/>
          <div className="container">
          <div className="banner-info" style={{marginBottom:0}}>
              
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>Website & Mobile App Development</h1>
                          <p>We have experience of 12000+ man hours of developing 100s of websites & Mobile Applications.</p>
                      </div>
                      <div className="col-sm-4">
                      <img src="/images/webApp.svg"/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
            <div className="solution">
                <div className="headingDataInfo">
                    <span className="childHead">Website & Mobile App Development</span>
                    <h1>We do offer creative <b> Services </b></h1>
                </div>
                <div className='container'>
              <div className="absolute">
                            <div className="row">
                                <div className="col-sm-6">
                                <div className="absolute-content seo">
                                    <h1>In Today's Digital Age, An Application is often the key to unlocking unparalleled growth and success for businesses of all sizes.</h1>
                                    <p>Our Application development services are designed to turn your app concept into a reality, whether you're looking to innovate, engage users, or streamline operations.</p>
                                    
                                    <ul>
                                        <li>Customized Solutions</li>
                                        <li>Platform Expertise</li>
                                        <li>User-Centric Design</li>
                                        <li>Cutting-Edge Technology</li>
                                        <li>Agile Development</li>
                                        <li>Prototyping and Testing</li>
                                        <li>App Security</li>
                                        <li>Scalability</li>
                                        <li>App Store Optimization (ASO)</li>
                                        <li>Post-Launch Support</li>
                                    </ul>
                                    <NavLink to="/contact"><a className='btn-primary'>Get Started</a></NavLink>
                                    </div>
                                </div>
                        <div className="col-sm-6">
                        <img src="/images/website-app_2.png"/>
                       </div>
                    </div>
                </div>
              </div>
            </div>
            <div className='ourServices'>
                <div className='container'>
                    <h5>OUR SERVICES</h5>
                    <p>We do website design & development,
                    Digital Marketing, SEO, Web App, Mobile
                    App. </p>
                    <br/><br/>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center px-4 py-5">
                            <div className="feature-box-icon"><img className="w-25" src="images/check-mark.svg" alt=""/></div>
                            <h3 className="feature-box-title">ESTABLISH YOUR DIGITAL FOOTPRINT.</h3>
                            <p className="feature-box-desc">Create a lasting first impression with our bespoke web design services, crafting visually stunning and userfriendly websites that elevate your brand's online presence</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center all-text-white bg-grad px-4 py-5 border-radius-3">
                            <div className="feature-box-icon"><img className="w-25" src="images/editor.svg" alt=""/></div>
                            <h3 className="feature-box-title">WHY WENIDI?</h3>
                            <p className="feature-box-desc">Partner with Wenidi for expert SEO services, resultdriven PPC campaigns, and dynamic social media marketing tailored to your business needs</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center px-4 py-5">
                            <div className="feature-box-icon"><img className="w-25" src="images/envelope.svg" alt=""/></div>
                            <h3 className="feature-box-title">WHAT WE DO?</h3>
                            <p className="feature-box-desc">We turn your digital goals into reality. Specializing in SEO, social media, and content marketing, we're here to help your brand shine in the digital universe.</p>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
            {/* <div className='oursBasicServiceStart bgWhite'>

            <div className="container">
                <div className="row">
                <div className="col-12 col-lg-8 mx-auto mb-5">
                    <div className="title text-center">
                    <h2>Our basic services starts only at,</h2>
                    <p>We have experience of 12000+ man hours of developing 100s of websites & Mobile Applications.</p>
                    </div>
                </div>
                </div>
                <div className="row spacePadding"> 
                <div className="col-md-6 ">
                    <div className="pricing-box h-100 shadow no-border box">
                    <div className="ribbon"><span>POPULAR</span></div>
                    <h5>Website Development <b>(India)</b></h5>
                    <br/>
                    <div className="plan-price"> <span className="price text-grad"> <sup className="text-grad">Rs.</sup>15000/- only* </span> including hosting, taxes extra</div>
                    <p>Responsive Website*</p>
                    <p>Custom Website Design *</p>
                    <p>Redesign Website*</p>
                    <p>Web Development*</p>
                    <p>Mobile App Design*</p>
                    <p>E-commerce Solutions*</p>
                    <NavLink to="/contact"><a className="btn btn-gradNew mt-4" href="#!">Get Started</a></NavLink> </div>
                </div>
                <div className="col-md-6 ">
                    <div className="pricing-box h-100 shadow no-border box">
                    <div className="ribbon"><span>POPULAR</span></div>
                    <h5>Website Development <b>(Outside India)</b></h5>
                    <br/>
                    <div className="plan-price"> <span className="price text-grad"> <sup className="text-grad">$</sup>300/- only* </span> including hosting, taxes extra</div>
                    <p>Responsive Website*</p>
                    <p>Custom Website Design *</p>
                    <p>Redesign Website*</p>
                    <p>Web Development*</p>
                    <p>Mobile App Design*</p>
                    <p>E-commerce Solutions*</p>
                    <NavLink to="/contact"><a className="btn btn-gradNew mt-4" href="#!">Get Started</a></NavLink> </div>
                </div>
                </div>
            </div>
            </div> */}
            <div class="trusted-data">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="content">
                                    {/* <p>"We are the most trusted business partner
                                    for Consulting solution by our clients"</p> */}
                                    <p>"Your trusted partner in IT consulting, crafting technology solutions that fit your needs."</p>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="br-right">
                                        <img src={ngright}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>       
          <Footer/>
      </div>
      </React.Fragment>
    );
}

export default WebApplication;