import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink } from 'react-router-dom';
import './blog.scss';
import Header from '../header';
import Footer from '../footer';
import contact from '../images/contact-us.png';
import shape from '../images/shape_bottom.png';
import msg from '../images/data-msg.png';
import call from '../images/data-call.png';

const Blog = () => {
    const [modal, setModal] = useState(false);
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_wpuo9ix', 'template_m9qyr5e', form.current, 'Qq6Y5iaRh_iyI0Zbr')
      .then((result) => {
          console.log(result.text);
            setModal(true)
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
      <div>
          <Header/>
          <div className='outerBannerBlogInfo'>
             <div className="elementorBackgroundOverlay"></div>
             <div className='container'>
                <div className='bolgCntnt'>
                    <h2>Blog</h2>
                    <p>One of the best things about our team is our passion for sharing knowledge with others. Whether we’re collaborating on innovative solutions, diving into market trends, or highlighting some of our favorite platform tips and tricks, our experts have you covered.
                    </p>
                </div>
             </div>
          </div>
          <div className='sectionDataInfo'>
             <div className='container'>
                <div className='boxSectionInfo'>
                    <div className='flexInfoData'>
                        <div className='ondemand'>
                            <h4>NOW</h4>
                            <p>On-Demand</p>
                        </div>
                        <div className='leftInfo'>
                            <img src="https://apisero.com/wp-content/uploads/2021/07/WhatsApp-Image-2021-07-15-at-21.34.14.jpeg" alt="user"/>
                        </div>
                        <div className='leftInfo'>
                           <div className='contentInfoblog'>
                             <h2>How to Mule: TIBCO</h2>
                             <p>Are you currently working as a TIBCO developer but looking for your next career accelerator?</p>
                             <p>Whether you’re an experienced TIBCO developer or in the early stages of your career, wenidi How to Mule: TIBCO series will showcase why a career path in MuleSoft should be your next move.</p>
                             <div><a href="" className='btn btn-primary waves-effect waves-light'>Read more</a></div>
                           </div>
                        </div>
                    </div>
                </div>
             </div>
          </div>
          <Footer/>
      </div>
    );
}

export default Blog;