import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './wenidi-blogs.scss';
import Header from '../header';
import Footer from '../footer';
import about from '../images/about_us.png';
import leftContant from '../images/left-contant.png';
import { Helmet } from "react-helmet";
import StickyHeader from 'react-sticky-header';
import { NavLink } from 'react-router-dom';

const wenidi_blogs = () => {
  return (
      <div>
        <React.Fragment>
        <Helmet>
            <title>Digital Marketing Services in Noida | Boost Your Business Growth</title>
            <meta name="description" content="Looking for top digital marketing services in Noida? Discover how SEO, PPC, social media, and content marketing can elevate your business and reach your target audience." />
            <link rel="canonical" href="https://www.wenidi.com/wenidi-blogs" />
            <meta name="keywords" content="digital marketing services in Noida, SEO services Noida, Noida digital marketing, social media marketing Noida, PPC services Noida, online marketing Noida" />
            
            
        </Helmet>
        <StickyHeader 
        header={
        
            <header className="header-top">
                <nav id="navbar_top" className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                <NavLink  className="navbar-brand" to="/"><img src="/images/wenidiLogo.png" className="App-logo" alt="logo" /></NavLink>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ml-auto action-buttons">
                        <li class="nav-item">
                        <NavLink exact activeClassName="active" className="nav-link" to="/">Home <span class="sr-only">(current)</span></NavLink>
                        </li>
                        <li class="nav-item dropdown">
                            <NavLink activeClassName="active" className="nav-link" to="/services">Services <span style={{position:'relative', top:0}}><i className="fa fa-angle-down" aria-hidden="true"></i></span></NavLink>
                            <ul class="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="/services">Our Services</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/seo-digital-marketing/">SEO & Digital Marketing</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/website-&-applications">Website & Applications</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/our-skillsets">Our Skillsets</NavLink></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <div className="nav-link">Product <span style={{position:'relative', top:0}}><i className="fa fa-angle-down" aria-hidden="true"></i></span></div>
                            <ul class="dropdown-menu">
                                <li><a className='dropdown-item' href="https://tocgenerator.wenidi.com/" target="_blank">Table of Contents Generator</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <NavLink activeClassName="active" className="nav-link" to="/about">About Us</NavLink>
                        </li>
                        <li class="nav-item dropdown">
                            <a className='nav-link' href='javascript:void(0)'> Career <span style={{position:'relative', top:0}}><i className="fa fa-angle-down" aria-hidden="true"></i></span></a>
                            <ul class="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="/career">Current Positions</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/internship-program">Internship Program</NavLink></li>
                            </ul>
                            </li>
                        <li class="nav-item">
                            <NavLink activeClassName="active" className="nav-link" to="/wenidi-blogs">Blogs</NavLink>
                        </li>
                            <li class="nav-item">
                            <NavLink activeClassName="active" className="nav-link" to="/contact">Contact Us</NavLink>
                        </li>
                        </ul>
                    </div>
                </div>
                </nav>
            </header>
            } ></StickyHeader>
    </React.Fragment >
          <div className="container">
          <div className="banner-info">
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>Blogs</h1>
                          <p>At Wenidi, We Empower Businesses with Innovative IT Solutions: Wenidi’s Expert Guidance</p>                            
                      </div>
                      <div className="col-sm-4">
                         <img src={about}/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
          {/* <div className="container marketing">
          <div class="row featurette dataInfoCnt">
          <div class="col-md-5">
                  <img src={leftContant}/>
              </div>
              <div class="col-md-7">
              <h1>Who We Are?</h1>
              <p>We strive to build long-lasting partnerships with our clients, focusing on their success and delivering exceptional customer service, we aim to be at the forefront of the IT industry, constantly pushing the boundaries of what is possible and driving digital transformation for our clients and We envision a future where businesses can fully harness the power of technology to achieve their goals. </p>
              <p>We are dedicated to making that vision a reality through our innovative solutions and unwavering commitment to our clients.</p>
              </div>
              </div>
          </div> */}
          <div className="container marketing">
          <div class="row featurette">
              <div class="col-md-12 dataInfoCnt">
                <h6>Unlocking Growth: Digital Marketing Services in Noida</h6>
                <p>Imagine you’ve just launched your dream business in Noida. The website looks fantastic, your products are top-notch, and you’re ready to take on the world. But as days turn into weeks, you realize one thing – your target audience doesn’t know you exist yet. That's where digital marketing steps in.</p>
                <p>If you’re searching for "digital marketing services in Noida," you're on the right track. In today’s world, having a strong online presence can make or break your business. Let’s take a closer look at why digital marketing services are essential and how they can transform your business in Noida.</p>
                 <p>Why Digital Marketing Matters in Noida:</p>
                <p>Noida has grown into one of India’s major tech hubs, attracting businesses of all kinds. With increasing competition, standing out can be tough. Simply having a website or posting on social media isn’t enough anymore. You need a well-thought-out strategy to reach potential customers and grow your brand.</p>
                <p>Digital marketing does just that. It helps businesses in Noida tap into the online world by using targeted strategies that get results. From optimizing your website to ensuring your social media posts reach the right people, the right digital marketing services will help you cut through the noise.</p>
                <p>Key Digital Marketing Services to Boost Your Business:</p>
                <p>When you search for "digital marketing services in Noida," you’ll come across various agencies offering different packages. Here are some of the most effective services that can take your business to new heights:</p>
                <p>Search Engine Optimization (SEO)</p>
                <p>Picture this: a customer is looking for a product or service that you offer. But when they type it into Google, your competitor’s website shows up first. SEO helps ensure that your website ranks higher on search engine results pages, bringing more organic traffic and potential leads to your site. A digital marketing agency will work on improving your website’s visibility and relevance for keywords specific to your business.</p>
                <p>Pay-Per-Click Advertising (PPC)</p>
                <p>PPC advertising is like placing a billboard in front of potential customers who are already looking for what you offer. By targeting specific audiences through Google Ads or social media platforms, you can drive traffic to your website immediately. The best part? You only pay when someone clicks on your ad.</p>
                <p>Social Media Marketing</p>
                <p>In a bustling city like Noida, where social media plays a huge role in consumer decisions, marketing on platforms like Instagram, Facebook, and LinkedIn is crucial. Through engaging content, targeted ads, and strategic campaigns, digital marketing services can boost your brand's visibility and engagement with your audience.</p>
                <p>Content Marketing</p>
                <p>Ever heard the phrase, “Content is king”? That’s because high-quality, relevant content can establish your business as an industry leader. Whether it's blog posts, videos, or infographics, content marketing is a powerful way to build trust with your audience while improving your SEO ranking.</p>
                <p>Email Marketing</p>
                <p>A well-crafted email marketing campaign can be a game-changer. It allows you to build a direct connection with your customers, providing them with valuable information, promotions, or updates. Digital marketing agencies in Noida offer tailored email marketing strategies to keep your brand at the top of your customers' minds.</p>
                <p>Finding the Right Digital Marketing Services in Noida</p>
                <p>So, how do you find the best "digital marketing services in Noida" to suit your business needs? Start by looking for an agency that understands your industry, has a proven track record, and offers a range of services under one roof. Reading client reviews, asking for case studies, and having a clear discussion about their approach are great ways to determine if they’re the right fit.</p>
                <p>The Road to Growth</p>
                <p>Think of digital marketing as the engine driving your business growth in Noida. Whether you’re a startup, a local business, or a well-established company, having a digital strategy that aligns with your goals is key to unlocking new opportunities.</p>
                <p>From boosting your website's traffic to reaching the right audience through social media, the benefits are endless. So, the next time you find yourself searching for "digital marketing services in Noida," remember – this is your gateway to building a powerful, long-lasting online presence.</p>
                
              </div>
              {/* <div class="col-md-3">
                <div className='directorMsg'>
                   <img src="/images/director_new.png" alt="Puja Pandey"/>
                   <h3>Puja Pandey</h3>
                   <p style={{fontSize:16}}>Founder & Executive Director - Wenidi Technologies Pvt Ltd. </p> */}
                   {/* <div className='director'><a href='https://www.linkedin.com/in/puja-pandey-b1499920a/' target='_blank'>Connect Linkedin</a></div> */}
                {/* </div>
              </div> */}
              </div>
          </div>
          {/* <div className="container marketing">
          <div class="row featurette">
              <div class="col-md-9 dataInfoCnt">
                <h6>Meet the director of IT</h6>
                <p>I am delighted to bring you some exciting updates from the heart of our technological endeavors here at Wenidi. As the Director of IT, it has been a thrilling journey overseeing our efforts to enhance your digital experience.</p>
                <p>In response to the ever-evolving landscape of technology, our IT department has been diligently working on several initiatives aimed at fortifying and elevating our digital infrastructure. Our primary focus has been on ensuring robustness, reliability, and scalability, with significant investments made to upgrade our systems.</p>
                <p>In light of the increasing importance of cybersecurity, we have implemented state-of-the-art measures to safeguard your data. Our commitment to providing a secure environment remains unwavering, and we are dedicated to staying vigilant against emerging threats.</p>
                <p>To make your journey with Wenidi even more seamless, we are introducing new user-centric features and tools. Our aim is to provide you with an intuitive and efficient platform that caters to your unique needs.</p>
                <p>In embracing the power of cloud technology, we have seamlessly integrated cloud solutions into our workflow. This not only optimizes resource utilization but also fosters better communication and accessibility of data across teams.</p>
                <p>Beyond technological upgrades, our commitment extends to ongoing support and training. We want to ensure that you not only benefit from the enhancements we've made but also feel confident in utilizing the full potential of our digital ecosystem.</p>
                <p>Your feedback is crucial to us. Whether you have questions, suggestions, or thoughts to share, please don't hesitate to reach out. We value your input and are here to make your experience with Wenidi exceptional.</p>
                <p>Thank you for being an integral part of our journey. Together, we will continue to innovate and set new standards for digital excellence.</p>
              </div>
              <div class="col-md-3">
                <div className='directorMsg'>
                   <img src="/images/visnoi.png" alt="Neha Vishnoi"/>
                   <h3>Neha Vishnoi</h3>
                   <p style={{fontSize:16}}>Director of IT - Wenidi Technologies Pvt Ltd. </p> */}
                   {/* <div className='director'><a href='https://www.linkedin.com/in/puja-pandey-b1499920a/' target='_blank'>Connect Linkedin</a></div> */}
                {/* </div>
              </div>
              </div>
          </div> */}
          <Footer/>
      </div>
    );
}

export default wenidi_blogs;