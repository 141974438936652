import React from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './privacy.scss';
import Header from '../header';
import Footer from '../footer';
import privacy from '../images/privacy.png';

const Privacy = () => {
  return (
      <div>
          <Header/>
          <div className="container">
          <div className="banner-info">
             
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>Privacy Policy</h1>
                          <p>This Privacy Policy is designed to govern the manner in which Wenidi uses, maintains.</p>
                      </div>
                      <div className="col-sm-4">
                         <img src={privacy}/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
            <div class="container marketing">
              <div class="row featurette">
                 <div class="col-sm-12">
                     {/* <div class="info-hsn">
                         <h2>I – Information collection and controls</h2>
                         <p>We collect the information that we need. The information that you actively give us when you sign up for an account, register for an event, ask for customer support, or buy something from us. We store your name and contact information.</p>
<p>When you visit websites or use this software, we automatically log some basic information like how you got to the site, where you navigated within it, and what features and settings you use. We use this information to improve our websites and services.</p>
<p>We receive some information indirectly. If you ask about our products through one of our referral programs or sign in to one of our products through an authentication service provider like LinkedIn or Google, it will pass on your contact information to us. We'll use that information to complete the request that you made. If you engage with our brand on social media (for instance, liking, commenting, retweeting, mentioning, or following us), we'll have access to your interactions and profile information. We'll still have that information even if you later remove it from the social media site.
</p>
                     </div>
                     <div class="info-hsn">
                         <h2>What we do with your information</h2>
                         <p>We use your information to provide the services you've requested, create and maintain your accounts, and keep an eye out for unauthorized activity on your accounts. We also use it to communicate to you about the products you're currently using, your customer support requests, new products you may like, chances for you to give us feedback, and policy updates. We analyse the information to understand the user needs and to improve our websites and services.</p>
<p>We're required to have a legal basis for collecting and processing your information. In most cases, we either have your consent or need the information to provide the service you've requested to us. When that's not the case, we must demonstrate that we have another legal basis, such as our legitimate business interests.</p>
<p>You can decline certain kinds of information use either by not providing the information in the first place or by opting out later. You can also disable cookies to prevent your browser from giving us information, but if you do so, certain website features may not work properly. We completely disable third-party.</p>
<p>We limit access to your personal information to our employees and contractors who have a legitimate need to use it. If we share your information with other parties (like developers, service providers, domain registrars, and reselling partners), they must have appropriate security measures and a valid reason for using your information, typically to serve you.</p>
<p>The European Economic Area (EEA) provides certain rights to data subjects (including access, rectification, erasure, restriction of processing, data portability, and the right to object and to complain). “Wenidi’’ undertakes to provide you the same rights no matter where you choose to live.</p>
<p>We keep your personal information for as long as it is required for the purposes stated in this Privacy Policy. When we no longer have a legitimate need to process your information, we will delete, anonymize, or isolate your information, whichever is appropriate.
</p>
                     </div>
                     <div class="info-hsn">
                         <h2>II – Information that processes on your behalf</h2>
                         <p>If you handle other people's data using our website, such as information about your customers or employees, you are entrusting that data to us for processing. If you use a mobile app and give the app access to your contacts and photo library, you are entrusting data to us. The data you entrust to us for processing is called service data.</p>
<p>You own your service data. We protect it, limit access to it, and only process it according to your instructions. You may access it, share it through third-party integrations, and request that we export or delete it.</p>
<p>We hold the data in your account as long as you choose to use Services. After you terminate your account, your data will be automatically deleted from our active database within 6 months and from our backups within 3 months after that.</p>
<p>If you are in the European Economic Area and you believe that someone has entrusted your information to us for processing (for instance, your employer or a company whose services you use), you can request certain actions from us regarding your data. To exercise those data rights, please contact the person or company that entrusted the data to us and we will work with them on your request.
</p>
                     </div>
                     <div class="info-hsn">
                    <h2>III – General</h2>
                    <p>There are some limitations to the privacy we can promise you. We will disclose personal information if it's necessary to comply with a legal obligation, prevent fraud, enforce an agreement, or protect our users' safety. We do not currently honour Do Not Track signals from internet browsers; when a universal standard for processing them emerges, we will follow it.</p>
<p>Third-party websites and social media widgets have their own separate privacy policies. Always check the relevant privacy policy before sharing personal information with third parties.</p>

<p>You can always contact us to: ask questions about our privacy practices, request a GDPR-compliant Data Processing Addendum, alert us if you believe we have collected personal data from a minor, or ask to have your personal information removed from our blogs or forums. You can also check our Security Policy and Privacy Policy
We will contact you to let you know if we make any major changes to our privacy policy, or in the highly unlikely event that we ever decide to sell our business.
</p>
                    </div>
                    <div class="info-hsn">
                        <h1>Privacy Policy</h1>
                        <h2>Our Privacy Commitment</h2>
                        <p>“Wenidi” has worried about customer and user privacy, long before it became fashionable, politically correct, or legally binding to take such a position. </p>
                        <p>We ask the least amount of information necessary, gathering only what we believe is essential for doing business, or for the specific transaction at hand. We let customers know the information we have on them and allow them to opt out of specific engagements. </p>
                        <p>The goal of this policy is to make explicit the information we gather on our customers and users, how we will use it, and how we will not. This policy is unfortunately longer than we would like, but we must unambiguously address all the relevant cases. We will try and keep the language simple.
                        </p>
                    </div>
                    <div class="info-hsn">
                        <h2>Scope of this Privacy Policy</h2>
                        <p>This Privacy Policy applies to websites that link to it. It also applies to the products and services provided by us through website and mobile applications.</p>
                        <p>This Privacy Policy in three parts:</p>
                        <p>Part I – Information collection and controls</p>
                        <p>This part deals with how Information collects and uses information about website visitors, potential customers, users of “wenidi” products and services, and others who contact through forms or email addresses published on or linked to our websites.</p>
                        <p>Part II – Information that processes on your behalf</p>
                        <p>This part deals with how “wenidi” handles data that you entrust to us when you use our products and services, or when you share any personal or confidential information with us while requesting customer support.</p>
                        <p>Part III – General</p>
                        <p>This part deals with topics that are relevant to both Parts I and II, and other general topics such as Wenidi's security commitments and how we will inform you when we change this Privacy Policy.</p>
                    </div>
                    <div class="info-hsn">
                        <h1>Part I – Information collection and controls</h1>
                        <h2>What information “wenidi” collects</h2>
                        <p>We collect information about you only if we need the information for some legitimate purpose. Wenidi will have information about you only if </p>
                        <p>(a) you have provided the information yourself, (b) Wenidi has automatically collected the information, or (c) Wenidi has obtained the information from a third party. </p>
                         <p>We have described below the various scenarios that fall under each of those three categories and the information collected in each one.</p>
                        <h3>Information that you provide us</h3>
                        <p><b>i. Account signup:</b> When you sign up for an account to access one or more of our services, we ask for information like your name, contact number, email address, company name and country to complete the account signup process. You'll also be required to choose a unique username and a password for accessing the created account. You may also provide us with more information such as your photo, time zone and language, but we don’t require that information to sign up for an account. After signing up, you will have the option of choosing a security question and an answer to the security question — if you provide these, they will be used only while resetting your password.</p>
                        <p><b>ii. Event registrations and other form submissions:</b> We record information that you submit when you (i) register for any event, including webinars or seminars, (ii) subscribe to our newsletter or any other mailing list, (iii) submit a form in order to download any product, whitepaper, or other materials, (iv) participate in contests or respond to surveys, or (v) submit a form to request customer support or to contact us for any other purpose.</p>
                        <p><b>iii. Payment processing:</b> When you buy something from us, we ask you to provide your name, contact information, and credit card information or other payment account information. When you submit your card information, we store the name and address of the cardholder, the expiry date and the last four digits of the credit card number. We do not store the actual credit card number. For quick processing of future payments, if you have given us your approval, we may store your credit card information or other payment information in an encrypted format in the secured servers of our Payment Gateway Service Providers.</p>
                        <p><b>iv. Testimonials:</b> When you authorize us to post testimonials about our products and services on websites, we may include your name and other personal information in the testimonial. You will be given an opportunity to review and approve the testimonial before we post it. </p>
                        <p><b>v. Interactions with us:</b> We may record, analyse and use your interactions with us, including email, telephone, and chat conversations with our sales and customer support professionals, for improving our interactions with you and other customers.</p>
                        <h3>Information that we collect automatically</h3>
                        <p><b>i. Information from browsers, devices and servers :</b> When you visit our websites, we collect information that web browsers, mobile devices and servers make available, such as the internet protocol address, browser type, language preference, time zone, referring URL, date and time of access, operating system, mobile device manufacturer and mobile network information. We include these in our log files to understand more about visitors to our websites.</p>
                        <p><b>ii. Information from first party cookies and tracking technologies:</b> We use temporary and permanent cookies to identify users of our services and to enhance user experience. We embed unique identifiers in our downloadable products to track usage of the products. We also use cookies, beacons, tags, scripts, and other similar technologies to identify visitors, track website navigation, gather demographic information about visitors and users, understand email campaign effectiveness and for targeted visitor and user engagement by tracking your activities on our websites. We only use first-party cookies and do not use third-party cookies or other third-party tracking technologies on our websites. You can learn more about the cookies used on our websites. We also use first-party Local Storage Objects (LSOs) such as HTML5 to store content information and preferences to provide certain features.</p>
                        <p><b>iii. Information from application logs and mobile analytics :</b> We collect information about your use of our products, services and mobile applications from application logs and in-house usage analytics tools, and use it to understand how your business use and needs can improve our products. This information includes clicks, scrolls, features accessed, access time and frequency, errors generated, performance data, storage utilized, user settings and configurations, and devices used to access and their locations.</p>
                        <h3>Information that we collect from third parties</h3>
                        <p><b>i. Signups using federated authentication service providers :</b> You can log in to wenidi Services using supported federated authentication service providers such as LinkedIn, Microsoft and Google. These services will authenticate your identity and give you the option to share certain personal information with us, such as your name and email address.</p>
                        <p><b>ii. Referrals : </b>If someone has referred any of our products or services to you through any of our referral programs, that person may have provided us your name, email address and other personal information. You may contact us at to request that we remove your information from our database. If you provide us information about another person, or if another person gives us your information, we will only use that information for the specific reason for which it was provided to us.</p>
                        <p><b>iii. Information from social media sites and other publicly available sources :</b> When you interact or engage with us on social media sites such as Facebook, Twitter, Google+ and Instagram through posts, comments, questions and other interactions, we may collect such publicly available information, including profile information, to allow us to connect with you, improve our products, or better understand user reactions and issues. We must tell you that once collected, this information may remain with us even if you delete it from the social media sites. wenidi may also add and update information about you, from other publicly available sources.</p>
                    </div>
                    <div class="info-hsn">
                        <h2>Purposes for using information</h2>
                        <p>In addition to the purposes mentioned above, we may use your information for the following purposes:</p>
                        <ul>
                            <li>To communicate with you (such as through email) about products that you have downloaded and services that you have signed up for, changes to this Privacy Policy, changes to the Terms of Service, or important notices;</li>
                            <li>To keep you posted on new products and services, upcoming events, offers, promotions and other information that we think will be of interest to you;</li>
                            <li>To ask you to participate in surveys, or to solicit feedback on our products and services;</li>
                            <li>To set up and maintain your account, and to do all other things required for providing our services, such as enabling collaboration, providing website and mail hosting, and backing up and restoring your data;</li>
                            <li>To understand how users use our products and services, to monitor and prevent problems, and to improve our products and services;</li>
                            <li>To provide customer support, and to analyze and improve our interactions with customers;</li>
                            <li>To detect and prevent fraudulent transactions and other illegal activities, to report spam, and to protect the rights and interests of wenidi, wenidi’s users, third parties and the public;</li>
                            <li>To update, expand and analyze our records, identify new customers, and provide products and services that may be of interest to you;</li>
                            <li>To analyze trends, administer our websites, and track visitor navigations on our websites to understand what visitors are looking for and to better help them;</li>
                             <li>To monitor and improve marketing campaigns and make suggestions relevant to the user.</li>
                        </ul>
                    </div>
                    <div class="info-hsn">
                        <h2>Legal bases for collecting and using information</h2>
                        <p><b>Legal processing bases applicable to wenidi :</b> If you are an individual from the European Economic Area (EEA), our legal basis for information collection and use depends on the personal information concerned and the context in which we collect it. Most of our information collection and processing activities are typically based on (i) contractual necessity, (ii) one or more legitimate interests of wenidi or a third party that are not overridden by your data protection interests, or (iii) your consent. Sometimes, we may be legally required to collect your information, or may need your personal information to protect your vital interests or those of another person.</p>
                        <p><b>Withdrawal of consent :</b> Where we rely on your consent as the legal basis, you have the right to withdraw your consent at any time, but this will not affect any processing that has already taken place.</p>
                        <p><b>Legitimate interests notice :</b> Where we rely on legitimate interests as the legal basis and those legitimate interests are not specified above, we will clearly explain to you what those legitimate interests are at the time that we collect your information.</p>
                        <h2>Your choice in information use</h2>
                        <p><b>Opt out of non-essential electronic communications :</b> You may opt out of receiving newsletters and other non-essential messages by using the ‘unsubscribe’ function included in all such messages. However, you will continue to receive notices and essential transactional emails.</p>
                        <p> <b>Disable cookies :</b> You can disable browser cookies before visiting our websites. However, if you do so, you may not be able to use certain features of the websites properly.</p>
                         <p><b>Optional information :</b> You can choose not to provide optional profile information such as your photo. You can also delete or change your optional profile information. You can always choose not to fill in non-mandatory fields when you submit any form linked to our websites.</p>
                         <h2>Who we share your information with us</h2>
                         <p>We have access to the information covered in Part I. We do not sell any personal information. We share your information only in the ways that are described in this Privacy Policy, and only with parties who adopt appropriate confidentiality and security measures.</p>
                        <p><b>Employees:</b> Employees of wenidi have access to the information covered in Part I on a need-to-know basis. We require all our employees to follow this Privacy Policy for personal information that we share with them.</p>
                        <p><b>Third-party service providers :</b> We may need to share your personal information and aggregated or de-identified information with third-party service providers that we engage, such as marketing and advertising partners, event organizers, web analytics providers and payment processors. These service providers are authorized to use your personal information only as necessary to provide these services to us.</p>
                        <p><b>Domain registrars :</b> When you register a domain through us from domain name registrars, we share your name and contact information such as your physical address, email address and phone number with them as per the ICANN domain registration rules.</p>
                        <p><b>Other cases:</b> Other scenarios in which we may share the same information covered under Parts I and II are described in Part III.</p>
                        <h2>Your rights with respect to information we hold about you as a controller</h2>
                        <p><b>Right to access :</b> You have the right to access (and obtain a copy of, if required) the categories of personal information that we hold about you, including the information's source, purpose and period of processing, and the persons to whom the information is shared</p>
                        <p><b>Right to rectification :</b> You have the right to update the information we hold about you or to rectify any inaccuracies. Based on the purpose for which we use your information, you can instruct us to add supplemental information about you in our database.</p>
                        <p><b>Right to erasure :</b> You have the right to request that we delete your personal information in certain circumstances, such as when it is no longer necessary for the purpose for which it was originally collected.</p>
                        <p><b>Right to restriction of processing :</b> You may also have the right to request to restrict the use of your information in certain circumstances, such as when you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</p>
                        <p><b>Right to data portability :</b> You have the right to transfer your information to a third party in a structured, commonly used and machine-readable format, in circumstances where the information is processed with your consent or by automated means.</p>
                        <p><b>Right to object :</b> You have the right to object to the use of your information in certain circumstances, such as the use of your personal information for direct marketing.</p>
                        <p><b>Right to complain : </b>You have the right to complain to the appropriate supervisory authority if you have any grievance against the way we collect, use or share your information. This right may not be available to you if there is no supervisory authority dealing with data protection in your country.</p>
                         <h2>Retention of information</h2>
                         <p>We retain your personal information for as long as it is required for the purposes stated in this Privacy Policy. Sometimes, we may retain your information for longer periods as permitted or required by law, such as to maintain suppression lists, prevent abuse, if required in connection with a legal claim or proceeding, to enforce our agreements, for tax, accounting, or to comply with other legal obligations. When we no longer have a legitimate need to process your information, we will delete or anonymize your information from our active databases. We will also securely store the information and isolate it from further processing on backup discs until deletion is possible.   </p>
                    </div>
                    <div class="info-hsn">
                        <h2>Part II – Information that Wenidi processes on your behalf</h2>
                        <h3>Information entrusted and purpose</h3>
                        <p><b>Information provided in connection with services :</b> You may entrust information that you or your organization (“you”) control, to wenidi in connection with use of our services or for requesting technical support for our products. This includes information regarding your customers and your employees (if you are a controller) or data that you hold and use on behalf of another person for a specific purpose, such as a customer to whom you provide services (if you are a processor). The data may either be stored on our servers when you use our services, or transferred or shared to us as part of a request for technical support or other services.</p>
                         <p><b>Information from mobile devices :</b> When you elect to allow it, some of our mobile applications have access to the camera, call history, contact information, photo library, and other information stored on your mobile device. Our applications require such access to provide their services. Similarly, when you elect to provide access, location-based information is also collected for purposes including, but not limited to, locating nearby contacts or setting location-based reminders. This information will be exclusively shared with our mapping providers and will be used only for mapping user locations. You may disable the mobile applications' access to this information at any time by editing the settings on your mobile device. The data stored on your mobile device and their location information to which the mobile applications have access will be used in the context of the mobile application, and transferred to and associated with your account in the corresponding services (in which case the data will be stored on our servers) or products (in which case the data will remain with you unless you share it with us).</p>
                        <h3>Ownership and control of your service data</h3>
                        <p>We recognize that you own your service data. We provide you complete control of your service data by providing you the ability to (i) access your service data, (ii) share your service data through supported third-party integrations, and (iii) request export or deletion of your service data.</p>
                         <h3>How we use service data</h3>
                         <p>We process your service data when you provide us instructions through the various modules of our services. For example, when you generate an invoice, information such as the name and address of your customer will be used to generate the invoice; and when you use our campaign management service for email marketing, the email addresses of the persons on your mailing list will be used for sending the emails.</p>
                        <h3>Push notifications</h3>
                        <p>If you have enabled notification on our desktop and mobile applications, we will push notifications through a push notification provider such as Apple Push Notification Service, Google Cloud Messaging or Windows Push Notification Services. You can manage your push notification preferences or deactivate these notifications by turning off notifications in the application or device settings.</p>
                        <p><b>Third-party integrations you have enabled :</b> If you choose to enable any third-party integrations, you may be allowing the third party to access your service information and personal information about you. We encourage you to review the privacy practices of the third-party services and products before you enable integrations with them.</p>
                        <p><b>Other cases :</b> Other scenarios in which we may share information that are common to information covered under Parts I and II are described in Part III.</p>
                        <h3>Retention of information</h3>
                        <p>We hold the data in your account if you choose to use our Services. Once you terminate your user account, your data will eventually get deleted from active database during the next clean-up that occurs once in 6 months. The data deleted from active database will be deleted from backups after 3 months.</p>
                        <h3>Data subject requests</h3>
                        <p>If you are from the European Economic Area and you believe that we store, use or process your information on behalf of one of our customers, please contact the customer if you would like to access, rectify, erase, restrict or object to processing, or export your personal data. We will extend our support to our customer in responding to your request within a reasonable timeframe.</p>
                    </div>
                    <div class="info-hsn">
                        <h2>Part III – General</h2>
                        <h3>How secure is your information?</h3>
                        <p>We take data security very seriously and have taken steps to implement appropriate administrative, technical & physical safeguards to prevent unauthorized access, use, modification, disclosure or destruction of the information you entrust to us. If you have any concerns regarding the security of your data, we encourage you to check our Security Policy or write to us. </p>
                        <h3>Data Protection</h3>
                        <p>If you have any questions or concerns about our privacy practices with respect to your personal information, you can reach out to us.</p>
                        <h3>Do Not Track (DNT) requests</h3>
                        <p>Some internet browsers have enabled 'Do Not Track' (DNT) features, which send out a signal (called the DNT signal) to the websites that you visit indicating that you don't wish to be tracked. Currently, there is no standard that governs what websites can or should do when they receive these signals. For now, we do not take action in response to these signals.</p>
                        <h3>External links on our websites</h3>
                        <p>Some pages of our websites may contain links to websites that are not linked to this Privacy Policy. If you submit your personal information to any of these third-party sites, your personal information is governed by their privacy policies. As a safety measure, we recommend that you not share any personal information with these third parties unless you've checked their privacy policies and assured yourself of their privacy practices.</p>
                        <h3>Blogs and forums</h3>
                        <p>We offer publicly accessible blogs and forums on our websites. Please be aware that any information you provide on these blogs and forums may be used to contact you with unsolicited messages. We urge you to be cautious in disclosing personal information in our blogs and forums. We are not responsible for the personal information you elect to disclose publicly. Your posts and certain profile information may remain even after you terminate your account with us. To request the removal of your information from our blogs and forums, you can contact us.</p>
                         <h3>Social media widgets</h3>
                         <p>Our websites include social media widgets such as Facebook "like" buttons and Twitter "tweet" buttons that let you share articles and other information. These widgets may collect information such as your IP address and the pages you navigate in the website and may set a cookie to enable the widgets to function properly. Your interactions with these widgets are governed by the privacy policies of the companies providing them.</p>
                        <h3>Disclosures in compliance with legal obligations</h3>
                        <p>We may be required by law to preserve or disclose your personal information and service data to comply with any applicable law, regulation, legal process or governmental request, including to meet national security requirements.</p>
                        <h3>Enforcement of our rights</h3>
                        <p>We may disclose personal information and service data to a third party if we believe that such disclosure is necessary for preventing fraud, investigating any suspected illegal activity, enforcing our agreements or policies, or protecting the safety of our users.</p>
                        <h3>Business Transfers</h3>
                        <p>We do not intend to sell our business. However, in the unlikely event that we sell our business or get acquired or merged, we will ensure that the acquiring entity is legally bound to honour our commitments to you. We will notify you via email or through a prominent notice on our website of any change in ownership or in the uses of your personal information and service data. We will also notify you about any choices you may have regarding your personal information and service data.</p>
                        <h3>Compliance with this Privacy Policy</h3>
                        <p>We make every effort, including periodic reviews, to ensure that personal information you provide is used in conformity with this Privacy Policy. If you have any concerns about our adherence to this Privacy Policy or the way your personal information is used, kindly write to us.  We'll contact you, and if required, coordinate with the appropriate regulatory authorities to effectively address your concerns.</p>
                        <h3>Notification of changes</h3>
                        <p>We may modify the Privacy Policy at any time, upon notifying you through a service announcement or by sending an email to your primary email address. If we make significant changes to the Privacy Policy that affect your rights, you will be provided with at least 30 days' advance notice of the changes by email to your primary email address. If you think that the updated Privacy Policy affects your rights with respect to your use of our products or services, you may terminate your use by sending us an email within 30 days. Your continued use after the effective date of changes to the Privacy Policy will be deemed to be your agreement to the modified Privacy Policy. You will not receive email notification of minor changes to the Privacy Policy. If you are concerned about how your personal information is used, </p>
                    </div> */}
                    <div class="info-hsn">
                        <h2>Information We Collect</h2>
                        <p>Contact information (name, email, phone number) <br/>
                            Business information (company name, job title) <br/>
                            Project-related information (project details, requirements)<br/>
                            Communication records (emails, messages)</p>
                    </div>
                    <div class="info-hsn">
                        <h2>How We Use Your Information</h2>
                        <p>Providing software consulting services as per the agreed-upon scope. <br/>
                            Communicating with you regarding project updates, invoices, and other relevant information. <br/>
                            Improving our services and customer experience.<br/>
                            Complying with legal and regulatory obligations.</p>
                    </div>
                    <div class="info-hsn">
                        <h2>Data Security</h2>
                        <p>We take reasonable measures to protect your information from unauthorized access, loss, misuse, or alteration. However, no data transmission over the internet can be guaranteed as completely secure.</p>
                    </div>
                    <div class="info-hsn">
                        <h2>Data Sharing</h2>
                        <p>We do not sell, rent, or trade your personal information to third parties. We may share your information with our authorized partners and service providers who assist us in delivering our services.</p>
                    </div>
                    <div class="info-hsn">
                        <h2>Cookies and Tracking Technologies</h2>
                        <p>We may use cookies and similar tracking technologies to enhance your experience on our website and improve our services. You can adjust your browser settings to disable cookies.</p>
                    </div>
                    <div class="info-hsn">
                        <h2>Your Rights</h2>
                        <p>You have the right to access, update, or request deletion of your personal information in our records. You can do so by contacting us at info@wenidi.com/+919821297193, +919870143245. However, please note that certain information may need to be retained for legal and business purposes.</p>
                    </div>
                    <div class="info-hsn">
                        <h2>Changes to this Policy</h2>
                        <p>We reserve the right to update this privacy policy as needed. Any changes will be posted on our website with an updated effective date.</p>
                    </div>
                    <div class="info-hsn">
                        <h2>Contact Us</h2>
                        <p>If you have any questions, concerns, or requests related to your personal information or this privacy policy, please contact us at info@wenidi.com /+919821297193, +919870143245.</p>
                    </div>
                 </div>
              </div>
              </div>
          <Footer/>
      </div>
    );
}

export default Privacy;