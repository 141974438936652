import React, { useEffect } from 'react';
import {Route, Switch} from "react-router-dom";
import Home from './home';
import Contact from './contact';
import About from './about';
import Career from './career';
import './App.css';
import Privacy from './privacy';
import Terms from './terms';
import Services from './services';
import Blog from './blog';
import News from './news';
import Webinars from './webinars';
import Customer from './customer';
import Description from './jobDescription';
import Training from './training';
import WebApplication from './webApplication';
import SkillSets from './skill';
import SEOData from './seo';

import TagManager from 'react-gtm-module'
import wenidi_blogs from './wenidi-blogs';

const tagManagerArgs = {
  gtmId: 'GTM-PWWS9GJ7'
}


const App = () => {

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <>
    <Switch>
      <Route exact path='/' component={Home}/>
      <Route path='/contact/' component={Contact}/>
      <Route path='/about/' component={About}/>
      <Route path='/career/' component={Career}/>
      <Route path='/privacy/' component={Privacy}/>
      <Route path='/terms/' component={Terms}/>
      <Route path='/services/' component={Services}/>
      <Route path='/blog/' component={Blog}/>
      <Route path='/news/' component={News}/>
      <Route path='/webinar/' component={Webinars}/>
      <Route path='/customer/' component={Customer}/>
      <Route path='/job-description/' component={Description}/>
      <Route path='/internship-program/' component={Training}/>
      <Route path='/seo-digital-marketing/' component={SEOData}/>
      <Route path='/website-&-applications/' component={WebApplication}/>
      <Route path='/our-skillsets/' component={SkillSets}/>
      <Route path='/wenidi-blogs/' component={wenidi_blogs}/>
      <Description/>
    </Switch>
    </>
  );
};

export default App;
