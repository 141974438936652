import React from 'react';
import './testimonialClient.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function TestimonialsClient() {
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 5,

        }
    },
};
  return (
    <div className='owlDataclient'>
      <OwlCarousel className='owl-theme' items="1" loop margin={10}  dots nav autoplay {...options}>
          <div class='itemNew'>
            <span className="clientLog">
                <img style={{width: 55}} src="./Logo/headlock.jpeg" alt="client"/>
            </span>
          </div>
          <div class='itemNew'>
          <span className="clientLog">
              <img style={{width: 200}} src="./Logo/amit.png" alt="client"/>
          </span>
          </div>
          <div class='itemNew'>
          <span className="clientLog">
              <img style={{width: 130}} src="./Logo/sai.png" alt="client"/>
          </span>
          </div>
          <div class='itemNew'>
          <span className="clientLog">
              <img style={{width: 111}}  src="./Logo/nexus.png" alt="client"/>
          </span>
          </div>
          <div class='itemNew'>
          <span className="clientLog">
              <img src="https://globalpda.com/wp-content/uploads/2020/05/logo.png" alt="client"/>
          </span>
          </div>
          <div class='itemNew'>
          <span className="clientLog">
              <img src="./Logo/mine2.png" alt="client"/>
          </span>
          </div>
          <div class='itemNew'>
          <span className="clientLog">
              <img src="./Logo/senger.png" alt="client"/>
          </span>
          </div>
          <div class='itemNew'>
          <span className="clientLog">
              <img src="./Logo/baba_saheb.png" alt="client"/>
          </span>
          </div>
          <div class='itemNew'>
          <span className="clientLog">
              <img src="./Logo/yoginis.png" alt="client"/>
          </span>
          </div>
          <div class='itemNew'>
          <span className="clientLog">
              <img src="./Logo/gcs.png" alt="client"/>
          </span>
          </div>
          <div class='itemNew'>
          <span className="clientLog">
              <img src="./Logo/globalkidsolympiads_logo.png" alt="client"/>
          </span>
          </div>
          <div class='itemNew'>
          <span className="clientLog">
              <img src="./Logo/kaushik.png" alt="client"/>
          </span>
       </div>
    </OwlCarousel>
   </div>
    ); 
 }

export default TestimonialsClient;