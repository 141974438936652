import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './job.scss';
import jobData from "../dataJob"; // Import the job data array

const JobFilter = () => {
  // State to manage the filter options
  const [technologyFilter, setTechnologyFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  // Filtered jobs based on user selections
  const filteredJobs = jobData.filter((job) => {
    return (
      (technologyFilter === "" || job.technology === technologyFilter) &&
      (locationFilter === "" || job.location === locationFilter) &&
      (typeFilter === "" || job.type === typeFilter)
    );
  });

  return (
    <div>
        <div className="jobInfoD">
            <h1>Job Opportunities</h1>
            <p>At Wenidi, we attribute our success to the rich diversity and exceptional skills of our team. <br/> Explore opportunities with us and become a part of a culture that <br/>values <b>innovation</b>, <b>collaboration</b>, and <b>personal growth</b>.</p>
        </div>
    <div className="outerNameJob">
      {/* Location filter */}
      <div className="inputInfo">
            <label>
              Technology:
            </label>
            <select
                value={technologyFilter}
                onChange={(e) => setTechnologyFilter(e.target.value)}
                >
                <option value="">All</option>
                <option value="SEO">SEO</option>
                <option value="Data Engineering">Data Engineering</option>
                <option value="Java">Java</option>
                <option value="Python">Python</option>
                <option value="Salesforce">Salesforce</option>
                <option value="API & Integration">API & Integration</option>
                <option value="DevOps">DevOps</option>
                {/* Add more location options */}
            </select>
        </div>
        <div className="inputInfo">
            <label>
                Location:
            </label>
            <select
                value={locationFilter}
                onChange={(e) => setLocationFilter(e.target.value)}
                >
                <option value="">All</option>
                <option value="India">India</option>
                <option value="USA">USA</option>
                <option value="Mexico">Mexico</option>
                {/* Add more location options */}
                </select>
        </div>

        {/* Job type filter */}
        <div className="inputInfo">
        <label>
            Job Type:
            </label>
            <select
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
            >
            <option value="">All</option>
            <option value="Full-time">Full-time</option>
            <option value="Contract">Contract</option>
            {/* Add more job type options */}
            </select>
        </div>
        </div>
        {/* Display filtered jobs */}
        <div className="cardDataJobInfo">
            {filteredJobs.map((job) => (
            <div className="loopData" key={job}>
              <div className="RowInfoData">
                  <div className="leftInfoCnt">{job.technology}</div>
                  <div className="leftInfoCnt">{job.location}</div>
                  <div className="leftInfoCnt">{job.type}</div>
              </div> 
              <div className="leftInfoCntAllow"><NavLink to="/job-description"><button>{job.button}</button></NavLink></div>
            </div>
            ))}
        </div>
        {!filteredJobs &&<div className="noJobFound">No job found</div>}
    </div>
  );
};

export default JobFilter;