import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './home.scss';
import Header from '../header';
import Slider from '../slider';
import Main from '../main';
import Footer from '../footer';


const Home = () => {
  return (
      <div>
          <Header/>
          <Slider/>
          <Main/>
          <Footer/>
      </div>
    );
}

export default Home;