import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import 'bootstrap/dist/css/bootstrap.css';
import './training.scss';
import Header from '../header';
import Footer from '../footer';
import career from '../images/career.png';
import leftCareer from '../images/leftCareer.png';
import rightCareer from '../images/rightCareer.png';
import JobFilter from '../jobFilter';
import { NavLink } from 'react-router-dom';
import { Alert } from 'bootstrap';
import TestimonialsEmployee from '../testimonialEmployee';

const Training = () => {
  const [modal, setModal] = useState(false);
  const form = useRef();

const sendEmail = (e) => {
  e.preventDefault();

  emailjs.sendForm('service_wpuo9ix', 'template_m9qyr5e', form.current, 'Qq6Y5iaRh_iyI0Zbr')
    .then((result) => {
        console.log(result.text);
          setModal(true)
    }, (error) => {
        console.log(error.text);
    });
};
  return (
      <div>
          <Header/>
          <div className="container">
          <div className="banner-info bgdataUpdate">
              
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>Internship Program</h1>
                      </div>
                      <div className="col-sm-4">
                         <img src='/images/bg.png'/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="outerDataInfoJob">
              <div className="container">
                  <div className="content">
                    <div className="row">
                        <div className="col-sm-8">
                             <h5>Internship Program</h5>
                             <p>Wenidi's Internship Program is a unique opportunity designed for aspiring professionals eager to foster skills and gain real-world experience in the tech industry. Established with the objective of bridging the gap between academic learning and practical application, our program provides interns with a holistic view of our business operations, industry dynamics, and the challenges and rewards of our field.</p>
                             <p>Interns will collaborate closely with seasoned professionals, work on live projects, and be a part of teams that drive our business forward. This immersive experience aims to cultivate both technical and soft skills, preparing interns for successful careers in their field.</p>
                             <p>Beyond the hands-on work experience, our program emphasizes mentorship, networking, and learning sessions, ensuring that interns not only contribute to our organization but also grow personally and professionally. By the end of the tenure, interns will have a portfolio of accomplishments, a wider network, and a clearer perspective on their career trajectory.</p>
                             <p>Join us in this transformative journey, as we pave the way for the next generation of industry leaders.</p>
                             <p>Eligibility Criteria <br/>B Tech, BCA, MCA, M Tech</p>
                             <p>Duration & Schedule <br/>6 Months, 12 Months</p> 
                        </div>
                        <div className="col-sm-4">
                          <div className="get-in-touchJob">
                                    <h5>Get in Touch</h5>
                                    <p>Feel free to drop us a line below!</p>
                                    <form ref={form} onSubmit={sendEmail}>
                                  <div className="form-group">
                                    <label for="Name">Name </label>
                                    <input required type="text" className="form-control" name="user_name"></input>
                                  </div>
                                  <div className="form-group">
                                    <label for="email-id">Email </label>
                                    <input required type="email" className="form-control" name="user_email"></input>
                                  </div>
                                  <div className="form-group">
                                    <label for="mobile">Phone Number </label>
                                    <input required type="number" className="form-control" name="user_mobile"></input>
                                  </div>
                                  <div className="form-group">
                                    <label for="message">Message </label>
                                    <textarea required className="form-control" name="message"></textarea>
                                  </div>
                                  <div class="text-center">
                                    <input class="btn btn-primary waves-effect waves-light" type="submit" onReset={form} value="Send Message"/>
                                    </div>
                                  </form>
                                    {modal && 
                                      <div className='thanksYou'>
                                          <div class="wrapper-2">
                                          <h1>Thank you !</h1>
                                          <p>Your submission has been received successfully. Our team is eager to assist you and will be in touch asap.</p>
                                          <NavLink className="nav-link" to="/"> <button class="go-home">
                                          go home
                                          </button>
                                          </NavLink>
                                          </div>
                                      </div>
                                    }
                                </div>
                          </div>
                    </div>
                 </div>
              </div>
          </div>
          <Footer/>
      </div>
    );
}

export default Training;